import React, { startTransition, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import {
  faBars
} from "@fortawesome/free-solid-svg-icons"

import { Box, Divider, HStack } from "@chakra-ui/react";
import "./style.css"
import LCLogo from "../LCLogo/LCLogo";
import { Link } from "react-router-dom";



const handleClick = (anchor) => () => {
  const element = document.getElementById(anchor);

  var x = document.getElementById("with-hamburger-box");
    if (x.style.display != "" && x.style.display != "none") {
      x.style.display = "none";
    }
  
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

function Home() {
  return <a className="nav-item" onClick={handleClick("landing-section")}>Home</a>
}

function About() {
  return <a className="nav-item" onClick={handleClick("about-section")}>About</a>

}

function Skills() {
  return <a className="nav-item" onClick={handleClick("skills-section")}>Skills</a>

}

function Projects() {
  return <a className="nav-item" onClick={handleClick("projects-section")}>Projects</a>

}

function ContactMe() {
  return <a className="nav-item" onClick={handleClick("contactme-section")}>Contact Me</a>

}

function Mail() {
  return (
    <a href={"mailto: leomartcrisostomo@gmail.com"} target="_blank">
      <FontAwesomeIcon icon={faEnvelope} size="2x"></FontAwesomeIcon>
    </a>
  )

}

function Github(props) {
  return (
    <a href="https://github.com/cleomart" target="_blank" {...props}>
      <FontAwesomeIcon icon={faGithub} size="2x"></FontAwesomeIcon>
    </a>
  )
}

function Linkedin() {
  return (
    <a href="https://www.linkedin.com/in/crisostomoleomart/" target="_blank">
      <FontAwesomeIcon icon={faLinkedin} size="2x"></FontAwesomeIcon>
    </a>
  )
}

function Instagram() {
  return (
    <a href="https://www.instagram.com/leimort_/" target="_blank">
      <FontAwesomeIcon icon={faInstagram} size="2x"></FontAwesomeIcon>
    </a>
  )
}

const Header = () => {
  const headerRef = useRef(null); 
 
  useEffect(() => { 
    let prevScrollPos = window.scrollY; 
  
    const handleScroll = () => { 
      const currentScrollPos = window.scrollY; 
      const headerElement = headerRef.current; 
      if (!headerElement) { 
        return; 
      } 
      if (prevScrollPos > currentScrollPos) { 
        headerElement.style.transform = "translateY(0)"; 
      } else { 
        headerElement.style.transform = "translateY(-200px)"; 
        var x = document.getElementById("with-hamburger-box");
        if (x.style.display != "" && x.style.display != "none") {
          x.style.display = "none";
        }
      } 
      prevScrollPos = currentScrollPos; 
    } 
    window.addEventListener('scroll', handleScroll) 
  
    return () => { 
      window.removeEventListener('scroll', handleScroll) 
    } 
  }, []); 

  function hamburgerOnClick() {
    var x = document.getElementById("with-hamburger-box");
    if (x.style.display != "" && x.style.display != "none") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  function resetHeaderRight() {
    var x = document.getElementById("with-hamburger-box");
    if (window.innerWidth > 650) {
      x.style.display = "none";
    }
  }

  window.addEventListener("resize", resetHeaderRight)

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      ref={headerRef}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      backgroundColor="#18181b"
      className="header-section"
      zIndex={2}
      width="100vw"
    >
      <Box className="header-section-box">
        <Box className="without-hamburger-box">
          <LCLogo></LCLogo>
          <a className="hamburger" onClick={hamburgerOnClick}>
              <FontAwesomeIcon icon={faBars} size="2x"></FontAwesomeIcon>
          </a>
          <Box className="without-hamburger"><Mail></Mail></Box>
          <Box className="without-hamburger"><Github></Github></Box>
          <Box className="without-hamburger"><Linkedin></Linkedin></Box>
          <Box className="without-hamburger"><Instagram></Instagram></Box>
          <Box className="without-hamburger"><Home></Home></Box>
          <Box className="without-hamburger"><About></About></Box>
          <Box className="without-hamburger"><Skills></Skills></Box>
          <Box className="without-hamburger"><Projects></Projects></Box>
          <Box className="without-hamburger"><ContactMe></ContactMe></Box>
        </Box>
        <Box id="with-hamburger-box" className="with-hamburger">
          <Box className="with-hamburger with-hamburger-nav"><Home></Home></Box>
          <Box className="with-hamburger with-hamburger-nav"><About></About></Box>
          <Box className="with-hamburger with-hamburger-nav"><Skills></Skills></Box>
          <Box className="with-hamburger with-hamburger-nav"><Projects></Projects></Box>
          <Box className="with-hamburger with-hamburger-nav"><ContactMe></ContactMe></Box>
          <Box className="with-hamburger with-hamburger-socials">
            <Mail></Mail>
            <Github></Github>
            <Linkedin></Linkedin>
            <Instagram></Instagram>
          </Box>
          </Box>
        </Box>
    </Box>
  );
};
export default Header;
export {
  Mail,
  Github,
  Linkedin,
  Instagram
}