import FullScreenSection from "../FullScreenSection"
import { Heading, Center, Box } from "@chakra-ui/react";
import ScrollAnimation from "react-animate-on-scroll";
import "./style.css"
import ProjectTemplate from "./ProjectTemplate/ProjectTemplate";
import LittleLemonMockup from "../../images/projects/Little Lemon Mockup.png"
import FroggerVid from "../../videos/Frogger.mp4"
import OleMockup from "../../images/projects/Ole Mockup.png"
import SelfImg from "../../images/projects/self.png"
import LSMockup from "../../images/projects/Lucky Shrub Mockup.png"
import LittleLemonDjangoMockup from "../../images/projects/Little Lemon Django Mockup.png"
import AVZVid from "../../videos/AV.mp4"
import PSVid from "../../videos/Pacman.mp4"

const projectsList = [
    {
        title: "Little Lemon",
        summary: "Built a website for a fictional Meditteranean Restaurant called Little Lemon \
                    with Reserve a Table feature",
        techList: ["HTML", "CSS", "Javascript", "React", "NPM", "Figma"],
        links: {
            github: "https://github.com/cleomart/little_lemon.git",
            website: "https://littlelemon.leomartcrisostomo.com/"
        },
        image: LittleLemonMockup
    },
    {
        title: "Ants VS Somebees",
        summary: "Developed the logic of a game of Ants (you) versus some bees. Place some ants in the colony \
                  grid that will perform an action based on the ant type. Once the bees \
                  enter the end of the colony grid by stinging the ants in their way or \
                  kill the queen, boom! game over! Do you think you have what it takes?",
        techList: ["Python"],
        links: {
            github: "https://github.com/cleomart/Ants-Vs-SomeBees.git"
        },
        vid: AVZVid
    },
    {
        title: "Pacman Search",
        summary: "Implemented search algorithms such as Depth First Search, Breadth First \
                  Search, Uniform Cost Search, and A* Search, and created a heuristic \
                  that helped Pacman to find paths through his maze world to reach a \
                  particular location",
        techList: ["Python"],
        links: {
            github: "https://github.com/cleomart/Pacman-Search.git"
        },
        vid: PSVid
    },
    {
        title: "Little Lemon 2",
        subtitle: "Django's Version (It's like Taylor's Version)",
        summary: "Built a website for a fictional Meditteranean Restaurant called Little Lemon \
                  with Book a Table feature using Django",
        techList: ["Django", "Python", "AWS ECS EC2 ECR SES", "SQLite", "HTML", "CSS"],
        links: {
            website: "https://littlelemondjango.leomartcrisostomo.com/"
        },
        image: LittleLemonDjangoMockup
    },
    {
        title: "Frogger Game",
        summary: "Implemented the logic of Frogger Game -- there's a little frog stuck at the bottom of a rectangular world \
                  composed of land and water where cars and lillies gradually moving \
                  from left-to-right. Can you help him cross?",
        techList: ["Java"],
        links: {
            github: "https://github.com/cleomart/Frogger-Game.git"
        },
        vid: FroggerVid
    },
    {
        title: "Olé",
        summary: "Contributed in creating an Android app that aids communication in the administration needs of landlords and tenants",
        techList: ["Java", "Android Studio"],
        links: {
            github: "https://github.com/cleomart/Ole.git"
        },
        image: OleMockup
    },
    {
        title: "Lucky Shrub",
        summary: "Designed a static website homepage for a fictional client called Lucky Shrub: a medium-sized garden \
                  design firm that specializes in garden design, maintenance, and landscaping. They also sells \
                  indoor and outdoor plants",
        techList: ["HTML", "CSS", "AWS S3"],
        links: {
            github: "https://github.com/cleomart/lucky_shrub.git",
            website: "http://luckyshrublrc.s3-website-us-west-2.amazonaws.com/index.html"
        },
        image: LSMockup
    },
    {
        title: "My Portfolio",
        summary: "Because why not put this here? :)",
        techList: ["HTML", "CSS", "Javascript", "React", "NPM", "AWS SES"],
        links: {
            website: "https://leomartcrisostomo.com/"
        },
        image: SelfImg
    }

]

function ProjectsSection() {
    return (
        <>
            <FullScreenSection id="projects-section">
                <ScrollAnimation duration={1.5}
                    animateIn='bounce'
                    initiallyVisible={true}>
                    <Heading as="h1" id="heading"> Projects </Heading>
                </ScrollAnimation>
            </FullScreenSection>
            {projectsList.map((item, index) => (
                <ProjectTemplate 
                    key={item.title}
                    number={index}
                    {...item}
                ></ProjectTemplate>

            ))}
        </>
    )
}

export default ProjectsSection;