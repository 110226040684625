import FullScreenSection from "../../FullScreenSection"
import { Heading, Center, Box, VStack } from "@chakra-ui/react";
import ScrollAnimation from "react-animate-on-scroll";
import "./style.css"
import { Github } from "../../Header/Header";
import {
   faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function ProjectTemplate({ number, title, summary, techList,
                            links, image, subtitle, vid}) {
    return (
        <FullScreenSection >
            <Box id="project-outer-box">
                <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                    <Heading id="project-title" as="h1">#{number} {title}</Heading>
                    {subtitle && <Heading id="project-subtitle" as="h2">{subtitle}</Heading>}
                </ScrollAnimation>
                <Box id="project-template">
                    <Box id="summary">
                        <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            {summary}
                        </ScrollAnimation>
                    </Box>
                    
                    <Box id="techs">
                        
                            {techList.map((item) => (
                                <ScrollAnimation key={item} animateIn='fadeInUp' duration={1.5}>
                                    <p key={item}>{item}</p>
                                </ScrollAnimation>
                            ))}
                        
                    </Box>
                    
                    <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                     <Box id="links">
                        
                            {links.github && <Box>
                                <Github href={links.github}></Github>
                            </Box>}
                            {links.website && <Box>
                                <a href={links.website} target="_blank"><FontAwesomeIcon icon={faUpRightFromSquare} size="2x" /></a>
                            </Box>}
                        
                        </Box>
                    </ScrollAnimation>
                    
                    <Box id="image">
                        {image ? 
                            <img src={image} alt="project mockup image"></img> :
                            <video  loop autoPlay muted id="project-vid">
                                <source src={vid} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                            </video>
                        }
                    </Box>
                </Box>
            </Box>
        </FullScreenSection>
    )
}

export default ProjectTemplate;