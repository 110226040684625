import React from "react";
import FullScreenSection from "../FullScreenSection";
import { Box, Heading, Center, VStack } from "@chakra-ui/react";
import "./style.css"
import ScrollAnimation from "react-animate-on-scroll"

import {
    faScrewdriverWrench,
    faDatabase,
    faGear,
    faWindowMaximize
} from "@fortawesome/free-solid-svg-icons"
import Card from "./Card";

const projects = [
  {
    title: "Frontend",
    icon: faWindowMaximize,
    skillList: ["HTML", "CSS", "Javascript", "React", "Figma", "NPM"]
  },
  {
    title: "Backend",
    icon: faGear,
    skillList: ["Python", "Java", "Kubernetes", "Docker", "AWS", 
    "Apache Kafka", "Django", "Flask", "Linux", "FAISS", "NLP", "Robot Framework", 
    "Test Automation", "Maven", "Mlflow", "RESTful API"]
  },
  {
    title: "Database",
    icon: faDatabase,
    skillList: ["Elastic Search", "MongoDB", "Apache Cassandra", 
    "MySQL", "SQLite", "Redis"]
  },
  {
    title: "Tools",
    icon: faScrewdriverWrench,
    skillList: ["Jenkins", "Grafana", "Lense", "Kibana", "Jira", "VSCode", "IntelliJ",
     "Pycharm", "Bitbucket", "Github", 
    ]
  }
];

const SkillsSection = () => {
  return (
    <FullScreenSection
      id="skills-section"
    >
      <Box>
        <ScrollAnimation animateIn='fadeInLeft' duration={1.5}>
            <Heading as="h1" id="skills-section-header">
              Skills
          </Heading>
        </ScrollAnimation>
        <Box
          className="skills-section-body"
        >
          {projects.map((project) => (
             <ScrollAnimation animateIn='fadeInRight' duration={1.5} key={project.title}>
                 <Card
                  key={project.title}
                  title={project.title}
                  skillList={project.skillList}
                  icon={project.icon}
                />
             </ScrollAnimation>
          ))}
        </Box>
      </Box>
    </FullScreenSection>
  );
};

export default SkillsSection;
