import LCLogoImg from "../../images/LC LOGO.jpg"
import "./style.css"

function LCLogo() {
    return (
        <a href="/">
            <img id="lc-logo" src={LCLogoImg}></img>
      </a>
    )
}

export default LCLogo;