import { Box, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./style.css"

const Card = ({ title, skillList, icon }) => {
  // Implement the UI for the Card component according to the instructions.
  // You should be able to implement the component with the elements imported above.
  // Feel free to import other UI components from Chakra UI if you wish to.

  return (
    <Box className="skill-card">
      <VStack>
        <Box className="skill-card-icon-box">
            <FontAwesomeIcon className="skill-card-icon" icon={icon} size="2x"></FontAwesomeIcon>
        </Box>
        
        <Box className="skill-card-body">
            <VStack>
                <Heading className="skill-card-heading" as="h2" size="md">{title}</Heading>
                {skillList.map((item) => (
                        <Text className="skill-card-item" textAlign="left"
                        key={item} id={item}>{item}</Text>
                ))}
            </VStack>
        </Box>
      </VStack>
    </Box>
  )
};

export default Card;
