import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import FullScreenSection from "../FullScreenSection";
import {useAlertContext} from "../../context/alertContext";
import ScrollAnimation from "react-animate-on-scroll";
import "./style.css"


function sendEmail(values, setResponse) {
  try {
    const AWS = require('aws-sdk');
    const ses = new AWS.SES({
      apiVersion: '2010-12-01',
      region:'us-west-2',

        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey:process.env.REACT_APP_SECRET_KEY});
    // Create sendEmail params 
  var params = {
    Destination: { /* required */
      CcAddresses: [
      ],
      ToAddresses: [
        'leomartcrisostomo@gmail.com',
        /* more items */
      ]
    },
    Message: { /* required */
      Body: { /* required */
        Html: {
        Charset: "UTF-8",
        Data: `<html>
        <head></head>
        <body>
          <h1>Inquiry: ${values.type}</h1>
          <p>Name: ${values.firstName}</p>
          <p>Email: ${values.email}</p>
          <p>Message: ${values.comment}</p>
        </body>
        </html>`
        },
        Text: {
        Charset: "UTF-8",
        Data: `
        Inquiry: ${values.type}
        Name: ${values.firstName}
        Email: ${values.email}
        Message: ${values.comment}
        `
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'LC Portfolio Contact Me'
      }
      },
    Source: 'leomartcrisostomo@gmail.com', /* required */
    ReplyToAddresses: [
      'leomartcrisostomo@gmail.com'
      /* more items */
    ],
  };


    ses.sendEmail(params).promise();
    setResponse({
      statusCode: 200,
      type: 'success',
      message: `Thanks for your submission ${values.firstName}, I will get back to you shortly!`,
    });
    return true;
  } catch (error) {
    console.log("ERROR: ", error)
    setResponse({type: 'error',
                message: 'Something went wrong, please try again later!'
              })
    return false;
  }
}

const LandingSection = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { onOpen } = useAlertContext();

  useEffect(() => {
    if (response != null) {
      onOpen(response.type, response.message)
      }
  }, [response])

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      type: "hireMe",
      comment: ""
    },
    onSubmit: (values, { resetForm }) => {
      if (sendEmail(values, setResponse)) {
        resetForm()
      }
    },
    validationSchema: Yup.object().shape( {
      firstName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      type: Yup.string().required('Required'),
      comment: Yup.string()
      .min(25, 'Must be at least 25 characters!')
      .max(1000, 'Must be at most 1000 characters')
      .required('Required')
    })
  });

  return (
    <FullScreenSection
      display="block"
      maxWidth="1000px"
      w="100%"
      id="contactme-section"
    >
      <Box id="contact-me-section-box">
      <ScrollAnimation animateIn='bounceInDown'duration={2}>
           <Heading as="h1" id="contactme-section-title">Contact Me</Heading>
        </ScrollAnimation>
        <Box rounded="md">
          <form onSubmit={formik.handleSubmit} w="100%">
            <VStack spacing={4}>
              <FormControl isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  id="name"
                  name="name"
                  {...formik.getFieldProps('firstName')}
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps('email')}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="bank-account">Bank Account Number</FormLabel>
                <Input
                  id="bank-account"
                  name="bank-account"
                  type="bank-account"
                  placeholder="I'm kidding! I think I'm funny sometimes"
                  disabled={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="type">Type of enquiry</FormLabel>
                <Select {...formik.getFieldProps('type')} id="type" name="type">
                  <option value="Freelance">Freelance project proposal</option>
                  <option value="HireMe">
                    Hire Me
                  </option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>
              <FormControl isInvalid={formik.touched.comment && formik.errors.comment}>
                <FormLabel 
                  htmlFor="comment">Your message</FormLabel>
                <Textarea
                  id="comment"
                  name="comment"
                  height={250}
                  {...formik.getFieldProps('comment')}
                />
                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
              </FormControl>
              <Button type="submit" style={{backgroundColor: "black", width: "100%", color: "white", height: "2.5em", borderRadius: "1em"}}
                      isLoading={isLoading}>
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </Box>
    </FullScreenSection>
  );
};

export default LandingSection;


// { 
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json',
//                'Accept': 'application/json',
//                'Origin': 'https://leomartcrisostomo.com',
//                'Authorization': 'Token 9a1f754812771625d21f8bae3c82fbfe429613ae' },
//     body: JSON.stringify({ name: values.firstName,
//                             email: values.email,
//                             inquiry: values.type,
//                             message: values.comment })
//   };

//   fetch(`http://ec2-44-228-101-245.us-west-2.compute.amazonaws.com:8000/contact-me`, requestOptions) 
//   .then((response) => response.json())
//   .then((jsonData) => {
//     console.log(jsonData)

//     setResponse({
//     type: 'success',
//     message: `Thanks for your submission ${values.firstName}, I will get back to you shortly!`,
//   }),
//   resetForm()}
//   )
//   .catch((error) => {
//     console.log("Error ", error)
//     setResponse({
//     type: 'error',
//     message: 'Something went wrong, please try again later!',
//   })});
// }