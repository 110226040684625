import "./style.css"
import FullScreenSection from "../FullScreenSection";
import LCProfile from "../../images/MRT01899.jpg"

import { Box, Heading } from "@chakra-ui/react"
import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from '@chakra-ui/react'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import  {
    Mail,
    Github,
    Linkedin,
    Instagram
} from "../Header/Header"

import ScrollAnimation from "react-animate-on-scroll";

const hobbies = [
    "listen to music/go to concerts",
    "buy overpriced coffee from Starbukcs/Philz",
    "travel and try new foods and learn new culture",
    "spend time with my supportive family and friends",
    "workout",
    "photography",
    "karaoke",
    "scroll on tiktok",
    "contemplate about my life's choices"
]
function About() {
    return (
        <FullScreenSection>
            <Box id="about-section">
                <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
                    <Heading as="h1" id="about-section-heading">About</Heading>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                    <p>Hi! My name is</p>
                </ScrollAnimation>
                <Box id="about-section-body">

                    <Box id="bio">
                        <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            <h2 id="bio-name">Leomart</h2>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            <p>I'm a <b>Backend Software Engineer</b> based in Sunnyvale, CA 
                                committed to creating <b>efficient and scalable 
                                web applications and distributed systems</b>.
                            </p>
                        </ScrollAnimation>
                        <br></br>
                        <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            <p>
                                I was a Backend Software Engineer at a startup company for the past three and a half years, and I was
                                involved in many aspects of <b>Agile Software Development Lifecycle</b> from getting 
                                the requirements, designing a solution, coding, testing, debugging,  deploying to 
                                production, and maintaining the features I have built - <b>end-to-end ownership</b>.
                            </p>
                        </ScrollAnimation>
                    </Box>
                    <Box id="image-box">
                        <img id="about-section-image" src={LCProfile} alt="Leomart Crisostomo Profile Picture"></img>
                    </Box>
                    <Box id="more-bio">
                    <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                        <Box id="can-do">
                          <p>My <b>curiosity</b> and <b>can-do attitude</b> enable me to constantly want to learn new things and achieve the desired goals/results. </p>
                        </Box>
                    </ScrollAnimation>
                        <br></br>
                        <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            <p>
                                I recently embarked
                                on learning Frontend development because I wanted to create my own web applications end-to-end, and here we both are on this website.
                                The possibilities are endless and this is just the beginning!
                            </p>

                        </ScrollAnimation>
                         <br></br>
                         <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            <p>
                                I am <b>looking for new opportunities</b> in which I can enhance and expand my software development skills
                                in backend.
                            </p>
                         </ScrollAnimation>
                    </Box>
                    <Box id="contact">
                            <ScrollAnimation animateIn='fadeInUp' duration={1.5}><Mail></Mail></ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp' duration={1.5}><Github></Github></ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp' duration={1.5}><Linkedin></Linkedin></ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp' duration={1.5}><Instagram></Instagram></ScrollAnimation>
                    </Box>
                    
                    <Box id="hobbies">
                       
                        <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                            <p>When I'm away from my 43-inch monitor, I like to</p>
                        </ScrollAnimation>
                        <List spacing={2} textDecoration="none">
                            {hobbies.map((item) => (
                                <ScrollAnimation key={item} animateIn='fadeInUp' duration={1.5}>
                                    <ListItem>
                                        <ListIcon as={BsFillArrowRightCircleFill}/>
                                            {item}
                                    </ListItem>
                                </ScrollAnimation>

                            ))}
                         </List>
                    </Box>
                </Box>

            </Box>
        </FullScreenSection>
    )
}

export default About;