import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header/Header";
import LandingSection from "./components/LandingSection/LandingSection";
import SkillsSection from "./components/SkillsSection/SkillsSection";
import ContactMeSection from "./components/ContactMeSection/ContactMeSection";
import Footer from "./components/Footer/Footer";
import { AlertProvider } from "./context/alertContext";
import Alert from "./components/Alert";
import About from "./components/About/About";
import ProjectsSection from "./components/ProjectsSection/ProjectsSection";

function App() {
  return (
    <ChakraProvider>
      <AlertProvider>
        <main>
          <Header />
          <LandingSection />
          <About></About>
          <SkillsSection />
          <ProjectsSection/>
          <ContactMeSection />
          <Footer></Footer>
          <Alert />
        </main>
      </AlertProvider>
    </ChakraProvider>
  );
}

export default App;
