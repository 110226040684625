import React from "react";
import { Avatar, HStack, Heading, VStack, Box } from "@chakra-ui/react";
import FullScreenSection from "../FullScreenSection";
import nameLogo from "../../images/Leomart MAIN.jpg"
import LastNameLogo from "../../images/Crisostomo MAIN.jpg"
import ScrollAnimation from "react-animate-on-scroll";
import { TypeAnimation } from 'react-type-animation';
import ProfilePic from "../../images/LC Profile.jpg"
import  "./style.css"
import ReactPlayer from 'react-player/lazy'


const bio1 = "Full-Stack Software Engineer who specializes in backend development";
const bio2 = "creating efficient, \nscalable distributed systems";

// Implement the UI for the LandingSection component according to the instructions.
// Use a combination of Avatar, Heading and VStack components.
const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    id="landing-section"
  >
    <VStack className="landing-section-box">
      {/* <Avatar name="Leomart Crisostomo" src={ProfilePic} size="2xl"></Avatar> */}
      <Box className="profile-pic-box">
          <img className="fade-text profile-pic" src={ProfilePic}></img>
        </Box>
      <Box className="grid">
        <Box className="item">
          <img className="fade-text full-name-img" src={nameLogo} alt="Leomart Text"></img>
        </Box>
        <Box className="item">
          <img className="fade-text full-name-img" src={LastNameLogo} alt="Crisostomo Text"></img>
        </Box>
      </Box>
      <Box>
        <TypeAnimation
        sequence={[
          // Same substring at the start will only be typed out once, initially
          "",
          'A results-driven Backend Software Engieni',
          800,
          'A results-driven Backend Software Engineer committed to creating efficient and scalable web applications and distributed systems with a caffeine addicti',
          100,
          'A results-driven Backend Software Engineer committed to creating efficient and scalable web applications and distributed systems with a caffeine habit',
          1000,
          'A results-driven Backend Software Engineer committed to creating efficient and scalable web applications and distributed systems with a caffeine habit :)',
          15000
        ]}
        wrapper="span"
        speed={50}
        className="landing-section-bio"
        repeat={Infinity}
      />
      </Box>
    </VStack>

  </FullScreenSection>
);

export default LandingSection;
